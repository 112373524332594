import React, { ButtonHTMLAttributes, FC, MouseEventHandler } from "react";
import classNames from "classnames";
import _ from "lodash";

export const Themes = {
  transparentDark: {
    base: "text-brand-grey-2",
    disabled: "text-brand-grey-4",
    interaction: "text-brand-grey-3",
  },
  transparentLight: {
    base: "text-brand-grey-10",
    disabled: "text-brand-grey-8",
    interaction: "text-brand-grey-11",
  },
  white: {
    base: "bg-brand-grey-10 border-brand-grey-10 text-brand-grey-4",
    disabled: "bg-brand-grey-10 text-brand-grey-9",
    interaction:
      "hover:bg-brand-grey-11 focus:bg-brand-grey-11 hover:border-brand-grey-11",
  },
  darkGrey: {
    base: "bg-brand-grey-5 border-brand-grey-5 text-brand-grey-11",
    disabled: "bg-brand-grey-5 text-brand-grey-8",
    interaction:
      "hover:bg-brand-grey-6 focus:bg-brand-grey-6 hover:border-brand-grey-11 text-white",
  },
  loginGrey: {
    base: "bg-brand-off-grey-1 border-brand-off-grey-1 text-brand-grey-11",
    disabled: "bg-brand-off-grey-1 text-brand-grey-8",
    interaction:
      "hover:bg-brand-off-grey-2 focus:bg-brand-off-grey-2 hover:border-brand-grey-11 text-white",
  },
  cyan: {
    base: "bg-brand-cyan-1 border-brand-cyan-1",
    disabled: "bg-brand-cyan-1 text-cyan-400",
    interaction:
      "hover:bg-brand-cyan-1 focus:bg-brand-cyan-1 hover:border-brand-cyan-1",
  },
  green: {
    base: "bg-green-600 border-green-500",
    disabled: "bg-green-500 text-green-700",
    interaction: "hover:bg-green-700 focus:bg-green-700 hover:border-green-700",
  },
  red: {
    base: "bg-red-600 border-600",
    disabled: "bg-red-500 text-red-700",
    interaction: "hover:bg-red-700 focus:bg-red-700 hover:border-red-700",
  },
  purple: {
    base: "bg-purple-600 border-purple-600",
    disabled: "bg-purple-500 text-purple-700",
    interaction:
      "hover:bg-purple-700 hover:border-purple-600 focus:bg-purple-700",
  },
} as const;

export const SubmitButton: FC<
  {
    className?: string;
    isDisabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  } & (
    | {
        theme?: keyof typeof Themes;
      }
    | {
        theme: "custom";
        /**
         * If theme is custom, className is required.
         *
         * Provide bg-*, text-*, hover:bg-*, hover:text-*
         */
        className: string;
      }
  )
> = ({
  className,
  isDisabled = false,
  onClick,
  theme = "green",
  children,
  type = "submit",
}) => {
  return (
    <button
      className={classNames([
        "inline-flex h-10 min-w-[100px] cursor-pointer items-center justify-center px-4 text-sm font-bold capitalize text-white transition",
        Themes[theme]?.base,
        className,
        {
          [Themes[theme]?.disabled]: isDisabled,
          [Themes[theme]?.interaction]: !isDisabled,
          "cursor-not-allowed": isDisabled,
        },
      ])}
      type={type}
      onClick={isDisabled ? _.noop : onClick}
    >
      {children}
    </button>
  );
};

export default SubmitButton;
